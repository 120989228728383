import validate from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/nuxt/dist/pages/runtime/validate.js";
import cancel_45stream_45global from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/middleware/cancelStream.global.ts";
import helix_45redirect_45global from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/middleware/helix-redirect.global.ts";
import maintenance_45global from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/middleware/maintenance.global.ts";
export const globalMiddleware = [
  validate,
  cancel_45stream_45global,
  helix_45redirect_45global,
  maintenance_45global
]
export const namedMiddleware = {
  connected: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/middleware/connected.ts"),
  leaderboard: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/middleware/leaderboard.ts"),
  markets: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/middleware/markets.ts")
}