import plugin_vue3_A0OWXRrUgq from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/node_modules/nuxt/dist/app/plugins/payload.client.js";
import buffer_bieIDf9x4O from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/nuxt-config/buffer.ts";
import amplitude_qcoZpE5aTU from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/plugins/amplitude.ts";
import handlers_oZWLY9pUCB from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/plugins/handlers.ts";
import i18n_VfGcjrvSkj from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/plugins/i18n.ts";
import plyr_m1vubbxwTm from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/plugins/plyr.ts";
import store_9xNuDHGAVU from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/plugins/store.ts";
import validation_30a3L3OexF from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/v2.8/wavely-og/wavely/plugins/validation.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  buffer_bieIDf9x4O,
  amplitude_qcoZpE5aTU,
  handlers_oZWLY9pUCB,
  i18n_VfGcjrvSkj,
  plyr_m1vubbxwTm,
  store_9xNuDHGAVU,
  validation_30a3L3OexF
]